 @font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/NotoSans/NotoSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/NotoSans/NotoSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: italic;
  font-weight: normal;
  src: url('./fonts/NotoSans/NotoSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/NotoSans/NotoSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/NotoSans/NotoSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/NotoSans/NotoSans-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/NotoSans/NotoSans-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/NotoSans/NotoSans-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/NotoSans/NotoSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/NotoSans/NotoSans-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/NotoSans/NotoSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/NotoSans/NotoSans-SemiBoldItalic.ttf') format('truetype');
}
